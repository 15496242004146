import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { Container, Row, Col } from 'react-bootstrap'
import Layout from '../components/layout'
import Seo from '../components/seo'

const SecondPage = () => (
  <Layout>
    <Seo title="About" />
    <Container className="about__container">
      <Row>
        <Col className="about__col">
          <div>
            Hi, I'm Ron, a full stack developer currently working in the Los
            Angeles area.&nbsp; Connect with me on{' '}
            <a href="https://www.linkedin.com/in/ronaldraypruitt/">LinkedIn </a>
          </div>
          <div>
            <h5>Currently:</h5>
            <li>Working full time at Edgecast/Yahoo</li>
            <li>
              Working in Javascript, React and C#. I recently transitioned from
              a SDET role to full stack
            </li>
          </div>
          <div>
            <h5>A few things about me:</h5>
            <li> I'm an avid gardener</li>
            <li>I have two dogs, two cats and three chickens</li>
            <li>I love to cook</li>
            <li>I'm passionate about art, design and technology</li>
            checkout out my
            <a href="../resume"> resume </a>
            page for details regarding my work experience
            <br />
          </div>
        </Col>
        <Col lg={6}>
          <StaticImage
            src="../images/profile-pic.png"
            quality={100}
            formats={['auto', 'webp', 'avif']}
            alt="me"
            width={500}
            style={{ marginBottom: '1.45rem' }}
          />
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default SecondPage
